<template>
  <v-container>
    <v-row align="start" justify="end">
      <h1><div class="subtitle navy-text"><strong>MEMBER</strong><br/></div></h1>
    </v-row>
    <v-row align="start" justify="end">
      <div class="base-text">ข้อมูลสมัครสมาชิก</div>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
    <br/>
    <div>
      <v-row>
        <v-col class="text-right navy-text font-weight-bold">
          ชื่อผู้สมัคร:
        </v-col>
        <v-col class="navy-text">
          <v-row class="d-none d-sm-flex">
            {{getProfile.fth}}  {{getProfile.lth}}
          </v-row>
          <v-row class="d-flex d-sm-none">
            {{getProfile.fth}}<br/>{{getProfile.lth}}
          </v-row>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col class="text-right grey--text">
          ระดับปัจจุบัน:
        </v-col>
        <v-col>
          <v-row :style="`color: ${colorRank[memberLevel]}`">
            {{memberLevel}}
          </v-row>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col class="text-right navy-text font-weight-bold">
          วันที่สมัครสมาชิก:
        </v-col>
        <v-col class="navy-text">
          <v-row>
            {{startMemberDate}}
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right navy-text font-weight-bold">
          สถานะการรับสิทธิ์:
        </v-col>
        <v-col class="navy-text">
          <v-row>
            {{acStatus}}
          </v-row>
        </v-col>
      </v-row>
      <div v-if="acActive">
        <v-row justify="center">
          <v-img
            max-width="500"
            width="300"
            :src="acImage"
          ></v-img>
        </v-row>
        <v-row justify="center" class="pt-4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a
                target="_blank"
                :href="acLink"
                @click.stop
                v-on="on"
              >
                <span class="red-text font-weight-bold subtitle" >
                  <u>
                    ลงทะเบียน Achieve Challenge
                  </u>
                </span>
              </a>
            </template>
            Opens in new tab
          </v-tooltip>
        </v-row>
        <v-row justify="center" class="navy-text pt-4">
          หากมีข้อสงสัยหรืออยากสอบถาม ติดต่อ
        </v-row>
        <v-row justify="center" class="navy-text">
          <span class="font-weight-bold">
            LINE Official Account:
          </span>
          <a class="pl-1" href="https://line.me/R/ti/p/@achieveplus" target="_blank">@achieveplus</a>
        </v-row>
        <v-row justify="center" class="navy-text">
          <span class="font-weight-bold">
            Facebook:
          </span>
          <a class="pl-1" href="https://www.facebook.com/Achieve.plus.th" target="_blank">Achieve.Plus</a>
        </v-row>
      </div>
      <!-- <v-row>
        <v-col class="text-right grey--text">
          หมดอายุ:
        </v-col>
        <v-col class="navy-text">
          <v-row>
            {{expireDate}}
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" :style="`color: ${calColor}`">
        ( เหลือเวลาอีก {{remDate}} วัน )
      </v-row> -->
      <!-- <v-row justify="center"
        class="d-none d-sm-flex pt-2"
      >
        <v-btn
          class="ma-2"
          color="error"
          outlined
          width="110px"
          to="/"
        >ไปหน้าหลัก</v-btn>
        <v-btn
          class="ma-2"
          color="error"
          width="110px"
          @click="benefits = true"
        ><span class="font-dialog">สิทธิสมาชิก</span></v-btn>
      </v-row> -->
      <!-- <v-row justify="center"
        class="d-flex d-sm-none"
      >
        <v-btn
          class="ma-2"
          color="error"
          width="110px"
          @click="benefits = true"
        ><span class="font-dialog">สิทธิสมาชิก</span></v-btn>
      </v-row>
      <v-row justify="center"
        class="d-flex d-sm-none"
      >
        <v-btn
          class="ma-2"
          color="error"
          outlined
          width="110px"
          to="/"
        >ไปหน้าหลัก</v-btn>
      </v-row> -->
    </div>
    <v-dialog
      v-model="benefits"
      max-width="450px"
    >
      <v-card>
        <v-img src="../../assets/image/memberPlat.png"
          max-height="450px"
          >
        </v-img>
        <v-card-actions>
        <v-row justify="center">
          <v-btn
            color="error"
            width="110px"
            @click="benefits = false"
          >ปิด</v-btn>
        </v-row>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import memberServices from '../../library/member';
import dateTimeService from '../../library/dateTime';
import settingService from '../../firebase/firestore/setting';

const { errorMsg } = require('../../static/errorMessage');

export default {
  name: 'Member',
  props: ['apply'],
  data: () => ({
    benefits: false,
    userData: undefined,
    fname: 'Achieve',
    lname: 'Plus',
    startMemberDate: '',
    memberLevel: undefined,
    expireDate: undefined,
    reducePoint: 0,
    isMember: false,
    currRank: {},
    currBar: 0,
    convertedPoints: 0,
    currPoint: 0,
    remDate: 0,
    colorRank: {
      Platinum: '#9966CC',
      Gold: '#FFD700',
      Silver: '#BEC2CB',
    },
    acStatus: '',
    acActive: false,
    acLink: '',
    acImage: '',
  }),
  async mounted() {
    try {
      this.$store.dispatch('setLoading', true);
      const { uid } = this.$store.getters.user;
      await this.$store.dispatch('getProfile', { uid });
      const { profile } = this.$store.getters;
      this.fname = profile.fth;
      this.lname = profile.lth;
      this.startMemberDate = dateTimeService.thDate(profile.memberStartDate);
      if (profile.achieveChallenge === 'successful') this.acStatus = 'มีสิทธิ์เข้าร่วม Achieve Challenge';
      else if (profile.achieveChallenge === 'pending') this.acStatus = 'กำลังตรวจเช็คสิทธิ์';
      else if (profile.achieveChallenge === 'fail') this.acStatus = 'ไม่มีสิทธิ์เข้าร่วม Achieve Challenge';
      else if (profile.achieveChallenge === 'used') this.acStatus = '.ใช้สิทธิ์เข้าร่วมไปแล้ว';
      else this.acStatus = '-';

      const acConfig = await settingService.acConfig();
      this.acActive = acConfig && acConfig.active;
      this.acLink = acConfig && acConfig.link ? acConfig.link : '';
      this.acImage = acConfig && acConfig.image ? acConfig.image : '';

      // this.convertedPoints = profile.convertedPoints;
      // this.currPoint = profile.currPoints;
      // if (profile.memberLevel) {
      //
      //   this.isActive = true;
      //   this.memberLevel = profile.memberLevel;
      //   // this.calProgress();
      //   this.calExp();
      // }
      this.$store.dispatch('setLoading', false);
    } catch (err) {
      this.$store.dispatch('setError', errorMsg['connection/unavailable']);
      this.$store.dispatch('setLoading', false);
    }
  },
  methods: {
    test() {
      this.isActive = !this.isActive;
    },
    isLoginCuma(value) {
      this.loginComplete = value;
    },
    async calProgress() {
      const { memberLevel, convertedPoints } = this.getProfile;
      const [config] = await memberServices.getPointConfig();
      const { pointRanks } = config;
      const [filter] = pointRanks.filter(e => e.rank === memberLevel);
      this.currRank = filter;
      this.currBar = (convertedPoints / filter.critiria) * 100;
    },
    calExp() {
      const { pointHis, memberExpDate } = this.getProfile;
      if (pointHis && pointHis.length) {
        // eslint-disable-next-line no-nested-ternary
        const early = pointHis.sort((a, b) => (a.exp > b.exp ? 1 : b.exp > a.exp ? -1 : 0));
        this.expireDate = dateTimeService.thDate(early[0].exp);
        this.reducePoint = early[0].points;
        this.remaningDate(early[0].exp);
      } else {
        this.expireDate = dateTimeService.thDate(memberExpDate);
        this.remaningDate(memberExpDate);
      }
    },
    remaningDate(expDate) {
      this.remDate = dateTimeService.diffDay(expDate);
    },
  },
  computed: {
    getCumaLinked() {
      return this.$store.getters.cumaLinked;
    },
    getLinked() {
      return this.$store.getters.cuma;
    },
    getProfile() {
      return this.$store.getters.profile;
    },
    convertedPoitns() {
      return this.$store.getters.profile.convertedPoitns;
    },
    calColor() {
      let color = '';
      if (this.remDate < 7) color = '#F5333F';
      if (this.remDate < 14) color = '#FF9D00';
      if (this.remDate < 30) color = '#0BB3B5';
      else color = '#1E78FF';
      return color;
    },
  },
  watch: {
    getLinked(value) {
      if (value) {
        this.linkCuma = true;
      } else if (!value) {
        this.linkCuma = false;
        this.$store.commit('setError', errorMsg['cuma/never']);
      }
    },
    getProfile(value) {
      if (value && value.fth && value.lth && value.fen && value.len
          && value.dob && value.sex && value.occupation && value.position
          && value.degree && value.workAge && value.salary && value.tel
          && value.lineId && value.contactEmail && value.address
          && value.subdistrict && value.district && value.province
          && value.postalCode && value.facebookEmail) {
        this.cumaEmail = value.cumaEmail;
        this.isMember = true;
      }
      if (value.workshopList && value.workshopList.length) {
        this.purchasedWorkshop = true;
      } else {
        this.purchasedWorkshop = false;
      }
      if (value.cumaEmail && value.cumaToken && !this.purchasedWorkshop) {
        const token = value.cumaToken;
        this.showLink = false;
        this.cumaEmail = value.cumaEmail;
        this.$store.dispatch('cumaCheckEnroll', { token })
          .then(() => {
            const { cuma } = this.$store.getters;
            if (cuma) {
              this.linkCuma = true;
            }
          })
          .catch(() => {
            this.linkCuma = false;
          });
      }
      this.fname = value.fth || '';
      this.lname = value.lth || '';
      this.memberLevel = value.memberLevel || '';
      if (value.memberExpDate) {
        const exp = new Date(value.memberExpDate.toMillis());
        this.expireDate = `${exp.getDate()}/${exp.getMonth() + 1}/${exp.getFullYear() + 543}`;
      }
      if (value.memberStartDate) {
        const start = new Date(value.memberStartDate.toMillis());
        this.startDate = `${start.getDate()}/${start.getMonth() + 1}/${start.getFullYear() + 543}`;
      }
    },
    getCumaLinked(value) {
      if (value) {
        this.linkCuma = true;
      }
    },
  },
};
</script>

<style scoped>

.font-dialog{
  font-family: "Morserrat", "Prompt", "sans-serif";
  font-size: 0.875rem;
  font-weight: 500;
  /* line-height: 24px; */
}
.font-dialog.new{
  font-size: 1rem;
  line-height: 24px;
}
.mt-24px{
  margin-top: 24px;
  margin-left: 20px;
}
</style>
