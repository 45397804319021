import moment from 'moment';

const thDate = (date) => {
  moment.locale('th');
  return date ? moment(typeof date === 'string' ? new Date(date) : date.toDate()).add(543, 'y').format('D MMM YY')
    : moment().add(543, 'y').format('D MMM YY');
};

const expPointThDateBC = () => {
  moment.locale('th');
  const formatDate = moment().format('DD MMM YYYY').split(' ');
  const thYear = String(parseFloat(formatDate[2]) + 543);
  const completedDate = moment().set({ year: thYear }).add(90, 'd').format('D MMM YY');
  return completedDate;
};

const diffDay = (expDateTS) => {
  const expDate = moment(typeof expDateTS === 'string' ? new Date(expDateTS) : expDateTS.toDate());
  const remDate = expDate.diff(moment(), 'days');
  return remDate > 0 ? remDate : 0;
};

export default {
  thDate,
  expPointThDateBC,
  diffDay,
};
